import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
    useCallback,
    useEffect,
} from "react";

const AppContext = createContext<{
    setActiveSection: React.Dispatch<React.SetStateAction<string | null>>;
    activeSection: string | null;
    theme: string;
    toggleTheme: () => void;
    scrolling: boolean;
    setScrolling: React.Dispatch<React.SetStateAction<boolean>>;
}>({
    setActiveSection: () => {},
    activeSection: null,
    theme: "light",
    toggleTheme: () => {},
    scrolling: false,
    setScrolling: () => {},
});

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }: { children: ReactNode }) => {
    const [activeSection, setActiveSection] = useState<string | null>(null);
    const [theme, setTheme] = useState("light");
    const [scrolling, setScrolling] = useState(false);

    const toggleTheme = useCallback(() => {
        const newTheme = theme === "light" ? "dark" : "light";
        setTheme(newTheme);
        localStorage.setItem("theme", newTheme); // Save theme preference
        document.documentElement.classList.toggle("dark", newTheme === "dark");
    }, [theme]);


    // Apply theme from localStorage on initial load
    useEffect(() => {
        const savedTheme = localStorage.getItem("theme") || "light";
        document.documentElement.classList.toggle("dark", savedTheme === "dark");
        setTheme(savedTheme);
    }, []);


    return (
        <AppContext.Provider
            value={{
                setActiveSection,
                activeSection,
                theme,
                toggleTheme,
                scrolling,
                setScrolling,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
