import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

function WhyUs() {
    let companyName = "Greens Garbage Grabbers";
    let community = "Springbank";
    let province = "AB";

    return (
        <div
            id="whyus"
            className="py-20 md:py-32"
            style={{ boxShadow: "var(--box-shadow)" }}
        >
            <div className="max-w-7xl mx-auto flex flex-col lg:flex-row gap-4 md:gap-8 px-10 lg:px-6">
                {/* Left Section: Why Choose Us Heading and Paragraph */}
                <div className="flex flex-1 flex-col p-6 md:p-12 shadow-lg rounded-xl bg-white dark:bg-darkThemeOffset">
                    <h2 className="text-2xl md:text-4xl md:text-start text-center font-bold mb-6 md:mb-12 mt-4 lg:text-left text-neutral-800 dark:text-neutral-100">
                        Why Us?
                    </h2>
                    <p className="text-[0.875rem] md:text-[1.03125rem] text-neutral-800 dark:text-neutral-300  lg:text-left">
                        For the past decade, {companyName} has been providing
                        services to the community of {community}. Over this
                        time, we have become aware of the challenges unique to{" "}
                        {community} and have focused our operations on helping
                        solve these challenges. We take pride in our community
                        and are motivated to continue to provide supreme
                        services to our friends and neighbors.
                    </p>
                </div>

                {/* Right Section: Grid List */}
                <div className="flex-1">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                        {/* Reason 1 */}
                        <div className="p-4 shadow-lg rounded-xl bg-white dark:bg-darkThemeOffset">
                            <div className="flex text-green-300 mb-4 justify-center">
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    size="2x"
                                />
                            </div>
                            <h3 className="text-lg md:text-xl font-semibold mb-4 flex justify-center text-neutral-800 dark:text-neutral-100">
                                Contract Free
                            </h3>
                            <p className="text-sm text-neutral-800 dark:text-neutral-300">
                                You are not required to sign contracts or make
                                any long-term commitments. Pause and resume
                                services as you need.
                            </p>
                        </div>

                        {/* Reason 2 */}
                        <div className="p-4 shadow-lg rounded-xl bg-white dark:bg-darkThemeOffset">
                            <div className="flex text-green-300 mb-4 justify-center">
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    size="2x"
                                />
                            </div>
                            {/* TITLE */}
                            <h3 className="text-lg md:text-xl font-semibold mb-4 flex justify-center text-neutral-800 dark:text-neutral-100">
                                To Your Door
                            </h3>
                            {/* DESCRIPTION */}
                            <div>
                                <p className=" text-sm text-neutral-800 dark:text-neutral-300">
                                    Enjoy hassle-free Waste and Recycling
                                    services with pickup right at your door.
                                    There is no need to haul bins to the street.
                                </p>
                            </div>
                        </div>

                        {/* Reason 3 */}
                        <div className="p-4 shadow-lg rounded-xl bg-white dark:bg-darkThemeOffset">
                            <div className="flex text-green-300 mb-4 justify-center">
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    size="2x"
                                />
                            </div>
                            {/* TITLE */}
                            <h3 className="text-lg md:text-xl font-semibold mb-4 flex justify-center text-neutral-800 dark:text-neutral-100">
                                Flexible Pricing
                            </h3>
                            {/* DESCRIPTION */}
                            <p className="text-sm text-neutral-800 dark:text-neutral-300">
                                Our flexible plans allow for weekly and biweekly
                                pickups. Whatever works best for your schedule.
                                We will make it work.
                            </p>
                        </div>

                        {/* Reason 4 */}
                        <div className="p-4 shadow-lg rounded-xl bg-white dark:bg-darkThemeOffset">
                            <div className="flex text-green-300 mb-4 justify-center">
                                <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    size="2x"
                                />
                            </div>
                            {/* TITLE */}
                            <h3 className="text-lg md:text-xl font-semibold flex justify-center mb-4 text-neutral-800 dark:text-neutral-100">
                                Locally Owned
                            </h3>
                            {/* DESCRIPTION */}
                            <p className="text-sm text-neutral-800 dark:text-neutral-300">
                                Locally owned and operating in {community},{" "}
                                {province}. We are a local business that
                                understands the community's unique needs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyUs;
