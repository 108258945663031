import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AppProvider } from "./context/AppContext";
import AppWrapper from "./AppWrapper/AppWrapper";
import AppContent from "./AppWrapper/pages/AppContent/AppContent";
import ContactWrapper from "./AppWrapper/pages/Contact/ContactWrapper";
import DeveloperWrapper from "./AppWrapper/pages/Developer/DeveloperWrapper";

function App() {
    return (
        <Router>
            <AppProvider>
                    <Routes>
                        <Route path="/" element={<AppWrapper />}>
                            <Route index element={<AppContent />} />
                            <Route
                                path="/contact"
                                element={<ContactWrapper />}
                            />
                            <Route
                                path="/developer"
                                element={<DeveloperWrapper />}
                            />
                        </Route>
                    </Routes>
            </AppProvider>
        </Router>
    );
}

export default App;
