import Footer from "../../components/Footer/Footer";
import Contact from "../Contact/Contact";

function ContactWrapper() {
    return (
        <div className="flex flex-col min-h-screen">
            <Contact />
            <Footer />
        </div>
    );
}

export default ContactWrapper;
