import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

let businessName = "Greens Garbage Grabbers";
let developerName = "Ty van Slooten";
let developerEmail = "mailto:tyvanslooten.dev@gmail.com";

function Developer() {
    return (
        <div className="mt-32 md:mt-40">
            <div className="max-w-3xl px-6 md:px-16">
                {/* Header Section */}
                <section className="mb-12 text-center space-y-4">
                    <h1 className="md:text-5xl text-3xl font-bold text-neutral-800 dark:text-neutral-100">
                        Developed By
                    </h1>
                </section>

                {/* About Me Section */}
                <section className="mb-6 md:mb-12 p-8 rounded-lg shadow-lg bg-white dark:bg-neutral-800">
                    <h2 className="text-2xl md:text-3xl font-semibold mb-4 text-neutral-800 dark:text-neutral-100">
                        About Me
                    </h2>
                    <p className="text-[0.875rem] md:text-[1.03125rem] leading-relaxed text-neutral-700 dark:text-neutral-100">
                        Hello, I’m{" "}
                        <span className="font-semibold">{developerName}</span> –
                        a freelance software developer who loves working on
                        projects that help local businesses like{" "}
                        <span className="font-semibold">{businessName}</span>{" "}
                        get the most out of their online presence. I’m always
                        excited to take on new challenges and work with
                        different people. Every project teaches me something
                        new, and I aim to bring a fresh perspective to each one.
                        If you're looking for a developer who’s curious, easy to
                        work with, and cares about your vision, let’s connect!
                    </p>
                </section>

                {/* Contact Section */}
                <section className="mb-12 p-8 rounded-lg shadow-lg bg-white dark:bg-neutral-800">
                    <h2 className="text-2xl md:text-3xl font-semibold mb-6 text-center md:text-left text-neutral-800 dark:text-neutral-100">
                        Contact
                    </h2>

                    <div className="max-w-lg space-y-6 text-[16px]  text-neutral-700 dark:text-neutral-100">
                        {/* Contact Links */}
                        <div className="flex flex-col sm:flex-row justify-center sm:justify-start space-y-4 sm:space-y-0 sm:space-x-6 ">
                            <a
                                href={developerEmail}
                                className="flex items-center justify-center sm:justify-start transition md:hover:text-green-300 duration-300"
                            >
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    className="mr-2"
                                />
                                tyvanslooten.dev@gmail.com
                            </a>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Developer;
