import Footer from "../../components/Footer/Footer";
import Developer from "../Developer/Developer";

function ContactWrapper() {
    return (
        <div className="flex flex-col min-h-screen">
            <div className="flex flex-1 items-center justify-center">
                <Developer />
            </div>
            <div className="flex flex-col">
                <Footer />
            </div>
        </div>
    );
}

export default ContactWrapper;
