import { useAppContext } from "../context/AppContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";


export const NavHelpers = () => {
    const { activeSection, setActiveSection, setScrolling, scrolling } = useAppContext();
    const navigate = useNavigate();
    const location = useLocation();

    // Scroll to a specified section ID
    const scrollToSection = (sectionId: string) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    // Unified handleNavClick function for both NavBar and Footer
    const handleNavClick = (section: string) => {
        setActiveSection(section);
        setScrolling(true);

        if (location.pathname !== "/") {
            navigate("/"); // Navigate to home page if not already there
        }
    };

    // Detects location change and triggers scroll if needed
    useEffect(() => {
        if (scrolling && location.pathname === "/") {
            scrollToSection(activeSection!);
            
            // Delay setting scrolling to false to allow smooth scrolling to complete
            const scrollTimeout = setTimeout(() => {
                if (scrolling) setScrolling(false); // Only update if still scrolling
            }, 250);
            
            return () => clearTimeout(scrollTimeout);
        }
    }, [location.pathname, activeSection, scrolling, setScrolling]);
    

    const GoToPage = (page: string) => {
        setActiveSection(page);
        navigate(`/${page}`);
        window.scrollTo(0, 0);
    };

    return {
        scrollToSection,
        handleNavClick,
        activeSection,
        GoToPage,
    };
};
