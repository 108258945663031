import { NavHelpers } from "../../../../../utils/NavHelper";

function Hero() {
    const { scrollToSection, GoToPage } = NavHelpers();

    const companyName = "Greens Garbage Grabbers";
    const Line1 = "Residential Garbage and Recycling Pickup Service";
    const Line2 = "Serving the Community of Springbank, Rockyview AB";

    return (
        <div
            id="hero"
            className="flex justify-center mt-32 md:mt-0 md:min-h-screen"
        >
            <div className="flex flex-col items-center justify-center text-center px-10 md:px-16 py-16 md:py-24 max-w-6xl font-bold text-neutral-800 dark:text-neutral-100">
                <h1 className="text-5xl md:text-6xl mb-8 md:mb-12">
                    {companyName}
                </h1>

                <p className="text-xl md:text-3xl font-semibold mb-6 text-neutral-800 dark:text-neutral-100">
                    {Line1}
                </p>

                <p className="text-md md:text-lg px-4 md:px-0 font-semibold mb-10 md:mb-16 text-neutral-600 dark:text-neutral-400">
                    {Line2}
                </p>

                <div className="flex flex-col md:flex-row gap-2 md:gap-4">
                    <button
                        onClick={() => scrollToSection("services")}
                        className="text-neutral-800 font-bold bg-green-300 md:hover:bg-green-400 text-sm md:text-lg py-1.5 px-4 md:py-2 md:px-6 rounded-lg transition duration-300"
                    >
                        services
                    </button>
                    <button
                        onClick={() => GoToPage("contact")}
                        className="text-neutral-800 font-bold bg-green-300 md:hover:bg-green-400 text-sm md:text-lg py-1.5 px-4 md:py-2 md:px-6 rounded-lg transition duration-300"
                    >
                        contact
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Hero;
