import Image from "../../../../../assets/Owner.jpg";
import { NavHelpers } from "../../../../../utils/NavHelper";

function AboutUs() {
    const { GoToPage } = NavHelpers();

    let ownerName = "Stacy";
    let province = "AB";
    let community = "Springbank";

    return (
        <section
            id="about"
            className="py-16 md:py-40"
            style={{ boxShadow: "var(--box-shadow)" }}
        >
            <div className="max-w-6xl mx-auto flex flex-col justify-center px-12 md:px-6">
                {/* Section Heading */}
                <h2 className="text-3xl md:text-4xl font-bold text-center md:text-left mb-12 md:mb-16 text-neutral-800 dark:text-neutral-100">
                    Meet The Owners
                </h2>

                <div className="md:flex md:items-start md:gap-x-12 mb-16 md:mb-0">
                    {/* Text Section */}
                    <div className="md:w-1/2 mb-8 md:mb-0">
                        <p className="px-2 md:px-0 text-[0.875rem] md:text-[1.03125rem] mb-12 md:mb-16 text-neutral-700 dark:text-neutral-100">
                            Hello, my name is {ownerName}. Our family moved to{" "}
                            {community}, {province}, in 1978. We purchased this business
                            in 2008, and it has been an amazing journey. We are
                            a small, family-run company, and we love getting to
                            know our customers in hopes of providing better,
                            more accommodating services.
                        </p>

                        {/* Contact Button (Visible only on large screens) */}
                        <button
                            onClick={() => GoToPage("contact")}
                            className="hidden md:flex text-[1rem] text-neutral-800 font-bold bg-green-300 md:hover:bg-green-400 py-2 px-4 rounded-md transition duration-300"
                        >
                            contact
                        </button>
                    </div>

                    {/* Image Section */}
                    <div className="md:w-1/2 flex justify-center md:justify-start">
                        <div className="rounded-2xl overflow-hidden shadow-md w-full max-w-sm md:max-w-md">
                            <img
                                src={Image}
                                alt="Company Owner"
                                className="w-full h-auto object-cover"
                            />
                        </div>
                    </div>
                </div>

                {/* Contact Button (Visible only on small screens) */}
                <div className="flex md:hidden justify-center">
                    <button
                        onClick={() => GoToPage("contact")}
                        className="bg-green-300 md:hover:bg-green-400 w-64 py-3 font-bold rounded-md text-sm transition duration-300"
                    >
                        contact
                    </button>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;
