import {
    faPhone,
    faChevronDown,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

function Contact() {
    const [openQuestionId, setOpenQuestionId] = useState<
        number | string | null
    >(null);

    const toggleDropdown = (questionId: number | string) => {
        setOpenQuestionId((prev) => (prev === questionId ? null : questionId));
    };

    const questions = [
        {
            id: 1,
            question: "What items can I dispose of through your service?",
            answer: "We accept household waste, including kitchen and bathroom waste.  You can recycle materials such as paper, cardboard, plastics, and cans.  Contact for specific items or materials.",
        },
        {
            id: 2,
            question: "Do you handle hazardous waste disposal?",
            answer: "No. Hazardous waste is not part of our pickup service. However, we can provide insights on proper disposal methods or refer you to appropriate facilities for disposal.",
        },
        {
            id: 3,
            question: "What happens if I have extra waste on occasion?",
            answer: "If you have extra waste beyond your usual pickup volume, you can arrange for an additional pickup for a small fee. Just let us know in advance, and we'll take care of it.",
        },
        {
            id: 4,
            question: "Do we need to sign a contract to get started?",
            answer: "No. There's no reason to get hung up on long-term contracts. You have the freedom to stop or pause whenever you need.",
        },
        {
            id: 5,
            question:
                "Do we need to bring our bins to the end of the driveway?",
            answer: "No. We collect your waste and recycling directly from your door, so you don’t have to haul heavy bins to the end of the driveway.",
        },
    ];

    return (
        <div className="flex flex-1 justify-center px-6 md:p-0 w-full">
            <div className="flex flex-col pt-32 pb-8 md:pt-44 md:pb-16 items-center w-full max-w-6xl space-y-8">
                {/* Contact Information */}
                <div className="p-6 md:p-8 rounded-lg shadow-lg flex flex-col items-center text-center w-full max-w-2xl bg-white dark:bg-darkThemeOffset">
                    <h2 className="text-2xl md:text-3xl font-bold mb-6 text-neutral-800 dark:text-neutral-100">
                        Get In Touch
                    </h2>

                    {/* Contact Details */}
                    <div className="flex flex-col items-center space-y-3">
                        <span className="text-[17px] md:text-[18px] font-semibold text-neutral-800 dark:text-neutral-100">
                            Mike van Slooten
                        </span>
                        <div className="flex items-center space-x-3 text-neutral-800 dark:text-neutral-300">
                            <FontAwesomeIcon icon={faPhone} />
                            <span className="text-[15px] md:text-[16px]">
                                (403) 667-1426
                            </span>
                        </div>
                    </div>
                </div>

                {/* FAQ Section */}
                <div className="p-6 md:p-8 rounded-lg shadow-lg w-full max-w-2xl bg-white dark:bg-darkThemeOffset">
                    <h2 className="text-2xl tracking-widest font-bold text-center mb-6 text-neutral-800 dark:text-neutral-100">
                        FAQ
                    </h2>

                    {/* ALL Question(s) Container */}
                    <div className="space-y-4">
                        {questions.map((q) => (
                            // Individual Question Container
                            <div key={q.id} className="border-b pb-4">
                                <div
                                    className="flex items-center justify-between cursor-pointer text-neutral-800 dark:text-neutral-100"
                                    onClick={() => toggleDropdown(q.id)}
                                >
                                    <span className="text-[15px] md:text-[17px] font-semibold">
                                        {q.question}
                                    </span>
                                    <FontAwesomeIcon
                                        className="ml-2"
                                        icon={
                                            openQuestionId === q.id
                                                ? faChevronDown
                                                : faChevronRight
                                        }
                                    />
                                </div>

                                {openQuestionId === q.id && (
                                    <div className="mt-2 text-[14px] md:text-[15px] transition duration-300 text-neutral-800 dark:text-neutral-300">
                                        {q.answer}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
