function Services() {
    return (
        <section
            id="services"
            className="md:pt-48 md:pb-48 pt-32 pb-24"
            style={{ boxShadow: "var(--box-shadow)" }}
        >
            <div className="max-w-4xl mx-auto flex flex-col px-8 md:px-0">
                {/* SECTION HEADING */}
                <h2 className="text-3xl lg:text-5xl font-bold mb-8 md:mb-16 text-center text-neutral-800 dark:text-neutral-100">
                    Our Services
                </h2>

                {/* Services Container */}
                <div className="flex flex-col lg:flex-row gap-4 md:gap-12 justify-center">
                    {/* Service 1 Container */}

                    <div className="lg:w-1/2 p-6 md:p-8 rounded-xl shadow-lg bg-white dark:bg-neutral-800">
                        {/* TITLE */}
                        <div className="flex-1 text-center mb-6">
                            <h3 className="text-xl md:text-2xl font-semibold mb-4 text-neutral-800 dark:text-neutral-100">
                                Waste Pickup
                            </h3>
                        </div>
                        {/* DESCRIPTION */}
                        <p className="text-[0.875rem] md:text-[1.03125rem] leading-relaxed text-neutral-800 dark:text-neutral-300">
                            Personalized waste pickup directly at your door. We
                            aim to take the hassle of waste management out of
                            your lives by providing convenient and flexible
                            services for all households of Springbank.
                        </p>
                    </div>

                    {/* Service 2 Container */}
                    <div className="lg:w-1/2 p-6 md:p-8 rounded-xl shadow-lg bg-white dark:bg-neutral-800">
                        {/* TITLE */}
                        <div className="flex-1 text-center mb-6">
                            <h3 className="text-xl md:text-2xl font-semibold mb-4 text-neutral-800 dark:text-neutral-100">
                                Recyling Pickup
                            </h3>
                        </div>
                        {/* DESCRIPTION */}
                        <p className="flex text-[0.875rem] md:text-[1.03125rem] leading-relaxed text-neutral-800 dark:text-neutral-300 md:leading-relaxed">
                            Contribute to better sustainability from the comfort
                            of your home. At Greens, ensuring your recyclables
                            are responsibly managed and sent to the proper
                            facilities is a priority. Your efforts should count!
                        </p>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
