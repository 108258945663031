import { useEffect, useState } from "react";
import { useAppContext } from "../../../context/AppContext";
import { useLocation } from "react-router-dom";
import { NavHelpers } from "../../../utils/NavHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faTimes,
    faMoon,
    faSun,
} from "@fortawesome/free-solid-svg-icons";

function NavBar() {
    const {
        activeSection,
        setActiveSection,
        theme,
        toggleTheme,
        scrolling,
    } = useAppContext();
    const location = useLocation(); // Detect current location
    const { GoToPage, handleNavClick } = NavHelpers();

    const [isScrolled, setIsScrolled] = useState(false); // has scrollED any length
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        if (scrolling) return; // Skip setup if scrolling is in progress

        const handleScroll = () => {
            setIsScrolled(window.pageYOffset > 0);
            if (location.pathname === "/") {
                if (window.pageYOffset === 0) {
                    setActiveSection("hero");
                }
            }
        };

        window.addEventListener("scroll", handleScroll);

        const sections = document.querySelectorAll("section[id]");
        const observerOptions = {
            root: null,
            rootMargin: "0% 0px -25% 0px",
            threshold: 0.5,
        };

        const observerCallback = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveSection(entry.target.id);
                }
            });
        };

        const observer = new IntersectionObserver(
            observerCallback,
            observerOptions
        );
        sections.forEach((section) => observer.observe(section));

        // Cleanup function to remove listeners and observers
        return () => {
            window.removeEventListener("scroll", handleScroll);
            sections.forEach((section) => observer.unobserve(section));
        };
    }, [setActiveSection, scrolling, location.pathname]);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div
            className={`fixed top-8 left-1/2 transform -translate-x-1/2 z-20 flex justify-between items-center w-8/12 h-16 rounded-xl backdrop-blur-md transition-all duration-500 py-8 px-10 ${
                isScrolled
                    ? "bg-black/25 border-[1px] border-black/50"
                    : "bg-transparent border-[1px] border-transparent"
            }`}
        >
            {/* Logo Wrap */}
            <div
                className="flex flex-1 h-16 cursor-pointer"
                onClick={() => handleNavClick("hero")}
            >
                <svg
                    id="a"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 642.86 640.67"
                    className="w-11 hover:scale-110 active:scale-100 transition-all duration-300 fill-gray-800 dark:fill-gray-200  hover:fill-green-300 dark:hover:fill-green-300"
                >
                    <path d="M412.35,230.41c-37.7-39.01-95.96-50.18-144.78-27.9-47.42,21.63-76.51,70.51-74,122,2.65,54.49,40,97.63,84.84,114,56.71,20.71,123.35-1.92,151.52-48.14,12.13-19.9,14.83-40.4,15.12-54.51,18-.15,36-.3,53.99-.46-1.92,18.2-8.58,56.62-37.1,92.75-36.16,45.81-95.64,72.96-156.3,67.12-79.52-7.66-146.48-70.62-159.3-147.38-12.66-75.76,32.87-132.36,39.48-140.31,6.83-8.21,53.2-62.12,130.59-64.39,97.88-2.87,153.53,79.54,156.71,84.43,11.1-9.24,22.2-18.48,33.3-27.73-5.18-7.45-73.13-101.62-191.23-98.62-11.66.3-111.8,4.56-173.11,90.5-6.45,9.05-50.92,73.52-37.05,159.75,13.06,81.2,74.29,159.62,166.43,181.62,101.48,24.22,195.96-30.58,239.76-100.78,23.19-37.17,29.43-74.26,31.23-97.46l54.47-.82c-1.61,27.56-8.07,72.08-34.14,118.22-44.26,78.33-133.54,140.91-240.16,141.41-105.88.5-202.69-60.35-248.69-152.39-6.27-12.54-55.48-115.21-7.92-226.14C115.68,99.29,247.3,29.69,371.37,52.46c126.07,23.13,187.78,131.24,196.74,147.54,12.04-10.17,24.07-20.34,36.11-30.51-14.58-26.64-43.17-70.44-93.87-107.28-18.43-13.39-111.79-78.2-238.33-58.52C113,28.43,40.92,164.92,34.9,176.8c-9.43,18.63-59.77,122.63-19.51,243.21,41.41,124.02,163.92,221.38,307.21,220.65,135.03-.69,241.78-88.24,288.92-184.08,23.47-47.72,29.78-92.65,31.33-122.9-.13-13.03-.26-26.05-.39-39.08-108.91-.5-217.82-1-326.73-1.49-.09,14.05-.19,28.1-.28,42.15,29.33.27,58.66.54,87.99.81-.28,5.66-1.54,14.68-6.85,23.96-2.61,4.56-5.3,7.62-8.44,11.15,0,0-10.07,11.33-20.65,18.39-14.98,9.99-63.56,24.35-99.6-5.71-30.01-25.03-29.47-62.64-29.34-66.94.87-27.86,15.68-57.03,43.27-71.67,31.33-16.63,71.39-10.57,98.67,15.52,10.61-10.12,21.21-20.24,31.82-30.36Z" />
                </svg>
            </div>

            {/* Desktop Buttons */}
            <div className="hidden sm:flex h-16 flex-col justify-center">
                <div className="flex font-bold h-8 items-end justify-end text-lg select-none text-gray-800 dark:text-gray-200">
                    <div
                        className={`text-center w-28 transition-all duration-300 hover:text-green-300 ${
                            activeSection === "services" ? "text-green-300" : ""
                        }`}
                        onClick={() => handleNavClick("services")}
                    >
                        services
                    </div>
                    <div
                        className={`text-center w-28 transition-all duration-300 hover:text-green-300 ${
                            activeSection === "about" ? "text-green-300" : ""
                        }`}
                        onClick={() => handleNavClick("about")}
                    >
                        about us
                    </div>
                    <div
                        className={`text-center w-28 transition-all duration-300 hover:text-green-300 ${
                            activeSection === "contact" ? "text-green-300" : ""
                        }`}
                        onClick={() => GoToPage("contact")}
                    >
                        contact
                    </div>
                    <div className="flex ml-8 w-7 items-center justify-center">
                        {/* Theme Toggle Button */}
                        <button
                            onClick={toggleTheme}
                            className="text-xl hover:text-green-300 transition-all duration-300"
                        >
                            <FontAwesomeIcon
                                icon={theme === "light" ? faMoon : faSun}
                            />
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Dropdown Button */}
            <div className="sm:hidden flex items-center text-gray-800 dark:text-gray-200">
                <button
                    onClick={toggleDropdown}
                    className="text-2xl hover:fill-green-300"
                >
                    <FontAwesomeIcon icon={isDropdownOpen ? faTimes : faBars} />
                </button>
            </div>

            {/* Dropdown Menu */}
            {isDropdownOpen && (
                <div
                    className={`text-[16px] absolute top-20 right-0 backdrop-blur-md rounded-lg transition-all duration-500 py-2 w-full text-gray-800 dark:text-gray-200 ${
                        isScrolled
                            ? "bg-black/25 border-[1px] border-black/50"
                            : "bg-transparent border-[1px] border-transparent"
                    }`}
                >
                    <div
                        className="text-center py-2 hover:text-green-300 cursor-pointer"
                        onClick={() => handleNavClick("services")}
                    >
                        services
                    </div>
                    <div
                        className="text-center py-2 hover:text-green-300 cursor-pointer"
                        onClick={() => handleNavClick("about")}
                    >
                        about us
                    </div>
                    <div
                        className="text-center py-2 hover:text-green-300 cursor-pointer"
                        onClick={() => GoToPage("contact")}
                    >
                        contact
                    </div>
                    <div className="text-center justify-center">
                        {/* Theme Toggle Button */}
                        <button
                            onClick={toggleTheme}
                            className="text-xl hover:text-green-300 transition-all duration-300"
                        >
                            <FontAwesomeIcon
                                icon={theme === "light" ? faMoon : faSun}
                            />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default NavBar;
