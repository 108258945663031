import NavBar from "./components/NavBar/NavBar";
import { Outlet } from "react-router-dom";

function AppWrapper() {
    return (
        <>
            <NavBar />
            <Outlet />
        </>
    );
}

export default AppWrapper;
