import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
// import { faEnvelope} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import { NavHelpers } from "../../../utils/NavHelper";

function Footer() {
    const { GoToPage, handleNavClick } = NavHelpers();

    return (
        <footer className="py-10" style={{ boxShadow: "var(--box-shadow)" }}>
            <div className="max-w-6xl mx-auto px-6 sm:px-12 lg:px-16">
                <div className="flex flex-wrap justify-between">
                    {/* Company Info */}
                    <div className="w-full md:w-1/3 mb-8 md:mb-0">
                        <h3 className="text-xl font-semibold mb-4 text-neutral-800 dark:text-neutral-100">
                            Greens Garbage Grabbers
                        </h3>
                        <p className="text-sm max-w-72 text-neutral-800 dark:text-neutral-300">
                            Keeping Springbank clean and green, one pickup at a
                            time.
                        </p>
                    </div>

                    {/* Quick Links */}
                    <div className="w-full md:w-1/4 mb-8 md:mb-0 select-none">
                        <h3 className="text-xl font-semibold mb-4 text-neutral-800 dark:text-neutral-100">
                            Quick Links
                        </h3>

                        <div className="flex md:flex-row flex-col gap-x-4">
                            <div className="mb-2 lg:mb-0">
                                <ul className="space-y-2">
                                    <li className="flex items-center text-neutral-800 dark:text-neutral-300">
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            className="text-[7px] mr-2"
                                        />
                                        <p
                                            className="text-sm md:hover:text-green-400"
                                            onClick={() =>
                                                handleNavClick("hero")
                                            }
                                        >
                                            Home
                                        </p>
                                    </li>
                                    <li className="flex items-center text-neutral-800 dark:text-neutral-300">
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            className="text-[7px] mr-2"
                                        />
                                        <p
                                            className="text-sm md:hover:text-green-400"
                                            onClick={() =>
                                                handleNavClick("services")
                                            }
                                        >
                                            Services
                                        </p>
                                    </li>
                                    <li className="flex items-center text-neutral-800 dark:text-neutral-300">
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            className="text-[7px] mr-2"
                                        />
                                        <p
                                            className="text-sm md:hover:text-green-400"
                                            onClick={() =>
                                                handleNavClick("whyus")
                                            }
                                        >
                                            Why Us
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <ul className="space-y-2">
                                    <li className="flex items-center text-neutral-800 dark:text-neutral-300">
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            className="text-[7px] mr-2"
                                        />
                                        <p
                                            className="text-sm md:hover:text-green-400"
                                            onClick={() =>
                                                handleNavClick("about")
                                            }
                                        >
                                            About
                                        </p>
                                    </li>
                                    <li className="flex items-center text-neutral-800 dark:text-neutral-300">
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            className="text-[7px] mr-2"
                                        />
                                        <p
                                            className="text-sm md:hover:text-green-400"
                                            onClick={() => GoToPage("contact")}
                                        >
                                            Contact
                                        </p>
                                    </li>
                                    <li className="flex items-center text-neutral-800 dark:text-neutral-300">
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            className="text-[7px] mr-2"
                                        />
                                        <p
                                            className="text-sm md:hover:text-green-400"
                                            onClick={() =>
                                                GoToPage("developer")
                                            }
                                        >
                                            Developer
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Contact Us */}
                    <div className="w-full md:w-1/4 mb-8 md:mb-0">
                        <h3 className="text-xl font-semibold mb-4 text-neutral-800 dark:text-neutral-100">
                            Contact Us
                        </h3>
                        {/* <p className="mb-2 flex items-center text-neutral-800 dark:text-neutral-300">
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                className="mr-2"
                            />
                            <a
                                href="mailto:info@example.com"
                                className="md:hover:text-green-400 text-sm"
                            >
                                email@email.com
                            </a>
                        </p> */}
                        <p className="flex items-center text-neutral-800 dark:text-neutral-300">
                            <FontAwesomeIcon icon={faPhone} className="mr-2" />
                            <a
                                href="tel:(123)456-7890"
                                className="md:hover:text-green-400 text-sm"
                            >
                                (403) 667-1426
                            </a>
                        </p>
                    </div>
                </div>

                {/* Footer bottom */}
                <div className="md:mt-8 text-center border-t border-gray-700/15 dark:border-gray-300/15 pt-4">
                    <p className="mt-4 text-neutral-800 dark:text-neutral-300">
                        &copy; 2024 Greens Garbage Grabbers. All rights
                        reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
