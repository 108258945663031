import Hero from "./components/Hero/Hero";
import Section1 from "./components/Services/Services";
import Section2 from "./components/WhyUs/WhyUs";
import Section3 from "./components/AboutUs/AboutUs";
import Footer from "../../components/Footer/Footer";

function AppContent() {
    return (
        <>
            <Hero />
            <Section1 />
            <Section2 />
            <Section3 />
            <Footer />
        </>
    );
}

export default AppContent;
